import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Icon from '~/components/Icon'
import { helpers } from '@common'

import styles from './style.m.scss'

const _className = helpers.classNames.react(styles)

export class Button extends PureComponent {
  constructor() {
    super()
    this.state = {
      isIphoneX: true,
    }
  }

  computeSize() {
    const { size } = this.props
    switch (size) {
      case 'large':
        return 'btn-large'

      case 'mid':
        return 'btn-mid'

      case 'small':
        return 'btn-small'

      default:
        return 'btn-large'
    }
  }

  preventDefaultClick = (e) => {
    const { onClick } = this.props
    e.preventDefault()

    onClick && onClick()
  }

  render() {
    const {
      text,
      icon,
      className,
      onClick,
      disabled,
      sharp,
      hollow,
      type,
      loading,
      inner,
      platform,
    } = this.props

    return (
      <button
        {..._className(`al-button ${className}`, {
          'btn-disable': disabled || loading,
          'btn-sharp': sharp,
          'btn-hollow': hollow,
          'btn-inner': inner,
          'btn-secondary': type === 'secondary',
          'btn-style-pc': platform === 'pc',
          btn_loading: loading,
          [this.computeSize()]: true,
        })}
        /* className={className} */
        onClick={this.preventDefaultClick}
      >
        {!loading ? null : (
          <i>
            <Icon
              icontype="svg"
              name="buttonLoading"
              {..._className('button-loading')}
            />
          </i>
        )}
        <span {..._className('font-lato-medium')}>{text}</span>
        {icon}
      </button>
    )

    // if (this.state.isIphoneX && detectIphoneX) {
    //   return (
    //     <div {..._className('iphonex-wrapper')}>
    //       <button
    //         {..._className(`al-button ${className}`, {
    //           'btn-disable': disabled || loading,
    //           'btn-sharp': sharp,
    //           'btn-hollow': hollow,
    //           [this.computeSize()]: true
    //         })}
    //         /* className={className} */
    //         onClick={this.preventDefaultClick}
    //       >
    //         {!loading ? null : (
    //           <i>
    //             <Icon
    //               icontype="svg"
    //               name="buttonLoading"
    //               {..._className('button-loading')}
    //             />
    //           </i>
    //         )}
    //         <span>{text}</span>
    //         {icon}
    //       </button>
    //       <div {..._className('iphonex-fill')} />
    //     </div>
    //   )
    // } else {

    // }
  }
}
